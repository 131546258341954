import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import App from './App/App';
import packageJSON from '../package.json';
import * as moduleInfo from './version';

import { getConfig } from './utils/functions';
import { processUserEventGeneric } from './utils/queryStrings';
import NexgenAPI from './api';

import Amplify, { Hub } from 'aws-amplify';

import { MainProps, MainState } from './types/app.types';
import { EventList } from './types/api.types';

import reportWebVitals from './reportWebVitals';
import * as serviceWorker from './serviceWorker';

import SessionControl from './App/SessionControl'

//analytics
import posthog from 'posthog-js';
import { TokenRefresher } from './utils/TokenRefresher';




serviceWorker.unregister();

declare global {
  interface Window { nexgen: any; nexgenInstance: any; rootNexgen: any; moduleInfo: any }
}
window.nexgen = window.nexgen || {};
window.rootNexgen = window.rootNexgen || {};
window.nexgenInstance = window.nexgenInstance || {};
window.moduleInfo = {
    ...moduleInfo,
    name: packageJSON.name
}

class Main extends React.Component<MainProps, MainState> {

  private rootPath: string = '/' + window.location.pathname.split('/')[1];
  private modulePath: string = process.env.NODE_ENV === 'development' ? process.env.REACT_APP_MODULE_PATH : this.rootPath;

  state: MainState = {
    sessionLimitReached: false,
    signedIn: false,
    inLegacyApp: false,
    authData: {
      Session: "",
      authenticationFlowType: "",
      challengeName: "",
      challengeParam: {},
      client: {},
      keyPrefix: "",
      pool: {},
      signInUserSession: {
        idToken: {
          jwtToken: "",
          payload: null
        }
      },
      storage: {},
      userDataKey: "",
      username: ""
    },

    AWSConfig: null
  }

  componentDidMount() {

    (async () => {

      let AppConfig = await getConfig(this.modulePath);
      if(AppConfig.hasOwnProperty('title')) document.title = AppConfig.title;

      if('metrics' in AppConfig) {
          if(!('provider' in AppConfig.metrics)) {
            console.error('No metric provider set');
          } else {
            switch(AppConfig.metrics.provider) {
                case "posthog":
                    if(!('api_key' in AppConfig.metrics)) {
                        console.error('No posthog api key set');
                        break;
                    }
                    if(!('api_host' in AppConfig.metrics)) {
                        console.error('No posthog api host set');
                        break;
                    }
                    posthog.init(AppConfig.metrics.api_key,{api_host:AppConfig.metrics.api_host});

                break;
                default:
                    break;
            }
        }
      }

      this.configureAmplify(AppConfig);

    })();
  }


  async configureAmplify(AWSConfig: any) {

    // initialize the nexgen api
    window.name = "ngr-player";
    window.rootNexgen = new NexgenAPI(this.rootPath, AWSConfig);
    window.nexgen = window.rootNexgen.createProxy(window, window.rootNexgen);

    Hub.listen('auth', async (data) => {
        switch (data.payload.event) {
            case 'signIn':
                // window.nexgen.refreshCredentials();
                await TokenRefresher();
                window.nexgen.callEvent(EventList.SIGNIN, data.payload.data);
             
                

                window.nexgen.saveReportingEvent({
                    activity: "login"
                });

                let currentUserPath = await window.nexgen.getCurrentLocation().pathname;
                    let userData = await window.nexgen.getCurrentUser('fdl');

                    if (currentUserPath === "") currentUserPath = "home";
                    
                    let mutationVariables: any = {
                        input: {
                            // userId: userData.userID,
                            // sessionUserId: userData.userID,
                            // sessionId: window.nexgen.authUserObject?.attributes?.sub ? window.nexgen.authUserObject.attributes.sub : "",
                            partition: window.nexgen.getPartition(),
                            ngModule: "ngr-player",
                            startsAt: new Date(),
                            action: "login",
                            navigation: currentUserPath,
                            navigationPath: currentUserPath,
                            // playerMeta: window.nexgen.os,
                            // ngModuleMeta: window.nexgen.history
                        },
                    };
                    
                    let upsert_results = window.nexgen.gqlrun(processUserEventGeneric, {
                        variables: mutationVariables,
                        // fetchPolicy: 'network-only'
                    });
                    console.log("upsert_results", upsert_results)


                if(sessionStorage.getItem('initial-search-string') && sessionStorage.getItem('initial-search-string') !== "") {
                    let ssoGatewayReq = window.nexgen.getQueryParam('ssoGatewayReq', sessionStorage.getItem('initial-search-string'));
                    if(ssoGatewayReq === 'true') {
                        window.nexgen.ssoGatewayLogin(data.payload.data, {search: sessionStorage.getItem('initial-search-string'), path: sessionStorage.getItem('initial-path') });
                        sessionStorage.removeItem('initial-search-string');
                        sessionStorage.removeItem('initial-path');
                        return;
                    }
                }
                
                // Profile matching by default, manifest can disable it via profileMatching key
                if(!AWSConfig || !('profileMatching' in AWSConfig) || AWSConfig.profileMatching === true) {

                    let exists = await window.nexgen.getCurrentNGRProfile(['cognitoUsername', 'cognitoUserID', 'fdrURI', 'email']);

                    if (!exists) {
                        // check profiles again
                        exists = await window.nexgen.profileExists(data.payload.data);
                        if(!exists) await window.nexgen.createProfileFromCognitoUser(data.payload.data);
                    }
                }


                // navigate to the original loaded path (set in App.tsx)
                let loc = window.nexgen.getCurrentLocation();
                let initialPath = sessionStorage.getItem('initial-path');
                let currentPath = loc.pathname + loc.hash;
                sessionStorage.removeItem('initial-path');

                if(initialPath && initialPath !== "" && initialPath !== currentPath) {
                    window.nexgen.navigate(initialPath);
                }
            break
        }
    });


    Amplify.configure({
      Auth: {...AWSConfig.awsexport.IdentityConfig, oauth: AWSConfig.oauth}
    });

    
    

    try {
        let user = await Amplify.Auth.currentAuthenticatedUser();
        this.setState({
            AWSConfig,
            signedIn: !!user
        })

    } catch(e) {
        this.setState({
            AWSConfig,
            signedIn: false
        })
    }
  }


  render() {

    if(!this.state.AWSConfig) return null;
    if(this.state.signedIn) window.nexgen.togglePreloader(true);

    if(this.state.sessionLimitReached){
      return (
        <SessionControl
            open={true}
            options={{
              //  privacyPath: this.privacyPath
            }}
            onEndAll={async () => {
              await window.nexgen.logout(true, "/", true);
            }}
            onSignOut={async () => {
              await window.nexgen.logout();
            }}
        />
    )
    }

    return <div className="app-wrapper">
              <App 
                main={this} 
                AWSConfig={this.state.AWSConfig} 
                loggedIn={this.state.signedIn} 
                authData={this.state.authData}
                AppSyncUrl={this.state.AWSConfig.awsexport.AppSync.Default.ApiUrl}
                />
            </div>
	}
}

ReactDOM.render(<Main />, document.getElementById('root'));


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();