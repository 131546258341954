import { RunOptions, SubscriptionOptions } from '../types/api.types';
import gql from 'graphql-tag';
import { Subscription } from 'apollo-client/util/Observable';

var sublist: { [key: string]: { id: string; subscription: Subscription }[] } =
  {};
var actionTypes: any = {
  mutation: 'mutate',
  query: 'query',
  subscription: 'subscribe',
};
const QueryMethods = {
  runQuery: function (query: string) {
    try {
      return gql`
        ${query}
      `;
    } catch (e) {
      console.log(e);
      return e;
    }
  },

  removeSubscriptions(mid: string) {
    if (mid in sublist) {
      sublist[mid].forEach((s: { id: string; subscription: Subscription }) => {
        s.subscription.unsubscribe();
      });
      sublist[mid] = [];
    }
  },

  cancelSubscription(id: string) {
    let mid = this.getProxyInstanceID();
    let sub = sublist[mid].find((s) => s.id === id);
    if (sub) {
      sub.subscription.unsubscribe();
    }
  },

  addToSubList: function (s: Subscription) {
    let mid = this.getProxyInstanceID();
    let sid = this.getUUID();
    if (!(mid in sublist)) sublist[mid] = [];
    sublist[mid].push({ id: sid, subscription: s });
    return sid;
  },

  runSubscription: function (subscription: SubscriptionOptions) {
    if (typeof subscription.query === 'string') {
      if (!subscription.query.match(new RegExp('^subscription', 'i'))) {
        subscription.query = `subscription {${subscription.query}}`;
      }
      subscription.query = gql(subscription.query);
    }
    let requestObject: any = { query: subscription.query };
    if (subscription.variables)
      requestObject.variables = subscription.variables;
    if (subscription.fetchPolicy)
      requestObject.fetchPolicy = subscription.fetchPolicy;
    let s = this.client.subscribe(requestObject).subscribe({
      next: ({ data }: any) => {
        subscription.callback(
          data[
            subscription.query.definitions[0].selectionSet.selections[0].name
              .value
          ]
        );
      },
    });
    return this.addToSubList(s);
  },

  run: async function (query: string, options: RunOptions = {}) {
    let {
      variables,
      subscribe,
      subscribe_keys,
      subscription,
      fetchPolicy,
      callback,
    } = options;

    //!
    if (!this.client) {
      let auth = await this.checkUserAuth();
      if (!auth) {
        console.error('Not logged in...');
        return;
      }
    } else {
      // NOTE: this might cause a delay in gql queries since we're waiting for the token to refresh before running the query on each query.
      await this.refreshCredentials();
    }
    //!

    let q = this.runQuery(query),
      result = null,
      type = q.definitions[0].operation,
      action = actionTypes[type],
      key = q.definitions[0].selectionSet.selections[0].name.value;

    if (!this.client) return null;

    try {
      let requestObject: any = { [type]: q };
      if (variables) requestObject.variables = variables;
      if (fetchPolicy) requestObject.fetchPolicy = fetchPolicy;

      if (action === 'subscribe') {
        if (callback) requestObject.callback = callback;
        requestObject.query = requestObject.subscription;
        return this.runSubscription(requestObject);
      } else {
        result = await this.client[action](requestObject);
      }

      if (action === 'mutate') {
        await this.client.reFetchObservableQueries();
      }

      if (action === 'query') {
        if (subscription) {
          this.runSubscription(subscription);
        } else if (subscribe) {
          console.warn(
            'This method of subscription is deprecated, please use the "subscription" key.'
          );

          if (!subscribe_keys) subscribe_keys = '';
          if (Array.isArray(subscribe_keys))
            subscribe_keys = subscribe_keys.join(' ');

          let observeKey = `${key}(${subscribe_keys})`;

          if (!this.observable.hasOwnProperty(observeKey)) {
            this.observable[observeKey] = {
              subscriptions: [],
              subscribe_keys,
              query: this.client.watchQuery({
                query: q,
                variables: variables ? variables : null,
              }),
            };

            if (
              typeof subscribe !== 'undefined' &&
              subscribe !== null &&
              typeof subscribe === 'function'
            ) {
              this.observable[observeKey].subscriptions.push(subscribe);
            }

            this.observable[observeKey].query.subscribe({
              next: ({ data }: any) => {
                for (let sub of this.observable[observeKey].subscriptions) {
                  if (typeof sub === 'function' && data)
                    sub(JSON.parse(JSON.stringify(data[key])));
                }
              },
            });
          } else {
            if (
              typeof subscribe !== 'undefined' &&
              subscribe !== null &&
              typeof subscribe === 'function'
            ) {
              this.observable[observeKey].subscriptions.push(subscribe);
            }
          }
        }
      }

      return result.data[key];
    } catch (e) {
      console.error(e.message);
      return null;
    }
  },
};

export default QueryMethods;
